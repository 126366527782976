<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Customers') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem" />
<!--            <MultipleDeleteButton v-show="$store.state.user.role === 0 || $store.state.user.role === 1"-->
<!--                                  :label="$t('Delete')"-->
<!--                                  @click="confirmItemsMultipleDelete"-->
<!--                                  :disabled="!selectedItems || !selectedItems.length" />-->
          </template>
<!--          <template v-slot:right>-->
<!--            <SelectButton @click="changeDataType($event)" v-model="selectedDataType" :options="dataTypes" class="p-pt-1 p-pb-1"/>-->
<!--          </template>-->
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else-if="!isLoading"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Customers')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :searchData="searchData"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: '', title: $t('Customer'),  width: '',sortable: false},
              {name: 'language', class: 'ti-world', width: '70px', sortable: true},
              {name: 'orders_count', class: 'ti-hummer', width: '70px', sortable: true},
              {name: 'offers_count', class: 'ti-clipboard', width: '70px', sortable: true},
              {name: 'cars_count', class: 'ti-car', width: '70px', sortable: true},
              {name: 'full_phone_number', title: $t('Phone'), sortable: false},
              {name: 'email', title: $t('Email'), sortable: false},
              {name: 'ordersSum', title: 'Orders sum', width: '9%', sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
<!--          <template v-slot:multiselectHeader>-->
<!--            <th v-show="$store.state.user.role === 0 || $store.state.user.role === 1" class="multiSelect-column-header">-->
<!--              <Checkbox @click="selectAll" :value="0" v-model="selectAllCheckbox"/>-->
<!--            </th>-->
<!--          </template>-->
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
<!--              <td v-show="$store.state.user.role === 0 || $store.state.user.role === 1">-->
<!--                <Checkbox :value="item.id" v-model="selectedItems"/>-->
<!--              </td>-->
              <td>
                <div class="p-d-flex p-ai-center">
                  <CustomerStatusHistoryButton
                      :customerStatus="item.customerStatus"
                      :customerId="item.id"
                      @change-customer-status="changeCustomerStatus"/>
                  <div v-if="item.type === constants.userTypes.legal_entity && item.company_name">
                    <div class="theme-color">{{  item.company_name }}</div>
                    <div class="table-small-text">{{ item.first_name }} {{ item.last_name }}</div>
                  </div>
                  <div v-else>
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                  </div>
                </div>
              </td>
              <td>
                <div class="customers-table-flag-wrapper">
                  <span :class="'customers-table-flag flag flag-' + item.flag_code"></span>
                </div>
              </td>
              <td>
                {{ item.orders_count ?? 0 }}
              </td>
              <td>
                {{ item.offers_count ?? 0 }}
              </td>
              <td>
                {{ item.cars_count ?? 0 }}
              </td>
              <td>
                <span v-if="item.type === constants.userTypes.legal_entity && item.company_name && item.company_phone_code && item.company_phone_number">+{{ item.company_phone_code }} {{ item.company_phone_number }}</span>
                <span v-else-if="item.phone_code && item.phone_number">+{{ item.phone_code }} {{ item.phone_number }}</span>
<!--                <span>{{ item.full_phone_number }}</span>-->
              </td>
              <td>
                <span style="white-space: pre-wrap" v-if="item.type === constants.userTypes.legal_entity && item.company_name">
                  <span v-if="item.company_email">{{ item.company_email }}</span>
                  <i v-else class="ti-minus"></i>
                </span>
                <span style="white-space: pre-wrap" v-else-if="item.email">
                  <span v-if="item.email">{{ item.email }}</span>
                  <i v-else class="ti-minus"></i>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                {{ formatCurrency(item.ordersSum) }}
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                <span v-if="item.creator">
                  <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>
                  <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                </span>
              </td>
              <td>
                <div class="p-d-flex">
                  <router-link :to="{ path: `/customers/${item.id}` }"><ViewButton/></router-link>
                  <EditButton :disabled="!checkUserCanEditCustomer(item.role) || (disableEditButton && isItemUpdated(item.id)) || !allDataLoaded || dataTableIsLoading"
                              @click="editItem(item)" />
                  <DeleteButton :disabled="item.role === constants.userRoles.superAdmin || dataTableIsLoading"
                                @click="confirmItemDelete(item)"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Customers')"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty"-->
<!--                         :showSearchInput="false"-->
<!--                         :searchData="searchData"-->
<!--                         @search-items="searchItems">-->
<!--&lt;!&ndash;          <template v-slot:multiselectHeader >&ndash;&gt;-->
<!--&lt;!&ndash;              <Checkbox v-show="$store.state.user.role === 0 || $store.state.user.role === 1" @click="selectAll" :value="0" v-model="selectAllCheckbox"/>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--&lt;!&ndash;                <div v-if="$store.state.user.role === 0 || $store.state.user.role === 1" class="p-mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <Checkbox :value="item.id" v-model="selectedItems"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Customer') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center p-flex-wrap">-->
<!--&lt;!&ndash;                    <div>{{ item.first_name }} {{ item.last_name }}</div>&ndash;&gt;-->
<!--                    <span v-if="item.type === constants.userTypes.legal_entity && item.company_name">-->
<!--                      <span class="theme-color p-mr-2">{{  item.company_name }}</span>-->
<!--                      <span class="table-small-text">{{ item.first_name }} {{ item.last_name }}</span>-->
<!--                    </span>-->
<!--                    <span v-else>-->
<!--                      <div>{{ item.first_name }} {{ item.last_name }}</div>-->
<!--                    </span>-->
<!--                    <div><span :class="'flag&#45;&#45;datatable flag flag-' + item.flag_code" class="p-ml-1"></span></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--&lt;!&ndash;                <div class="p-mb-3 p-d-flex p-ai-center">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="p-text-bold mobile-datatable__title">{{ $t('Role') }}:</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ computedRole(item.role) }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Counters') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div class="p-d-flex p-ai-center">-->
<!--                      <div>-->
<!--                        <span class="ti-hummer"></span>-->
<!--                        <span class="p-text-bold p-ml-1">{{ item.orders_count || 0 }}</span>-->
<!--                      </div>-->
<!--                      <div class="p-ml-3">-->
<!--                        <span class="ti-clipboard"></span>-->
<!--                        <span class="p-text-bold p-ml-1">{{ item.offers_count || 0 }}</span>-->
<!--                      </div>-->
<!--                      <div class="p-ml-3">-->
<!--                        <span class="ti-car"></span>-->
<!--                        <span class="p-text-bold p-ml-1">{{ item.cars_count || 0 }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Phone') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.type === constants.userTypes.legal_entity && item.company_name"><span v-if="item.company_phone_code">+</span>{{ item.company_phone_code }} {{ item.company_phone_number }}</span>-->
<!--                    <span v-else><span v-if="item.phone_code">+</span>{{ item.phone_code }} {{ item.phone_number }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Email') }}:</div>-->
<!--                  <span class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.type === constants.userTypes.legal_entity && item.company_name">{{ item.company_email }}</span>-->
<!--                    <span v-else>{{ item.email }}</span>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Created') }}</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div class="table-date-line">-->
<!--                      <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>-->
<!--                      <span v-if="item.creator" class="table-date-line">-->
<!--                        <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                        <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <router-link :to="{ path: `/customers/${item.id}` }"><ViewButton/></router-link>-->
<!--                  <EditButton :disabled="!checkUserCanEditCustomer(item.role) || ((disableEditButton && isItemUpdated(item.id)) || !allDataLoaded)"-->
<!--                              @click="editItem(item)" />-->
<!--                  <DeleteButton :disabled="item.role === constants.userRoles.superAdmin"-->
<!--                                @click="confirmItemDelete( item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <CustomerModal :visible="itemModal"
                       :item="item"
                       :taxes="taxes"
                       @update-items="updateItems"
                       @close="closeItemModal">
        </CustomerModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
// import CommonTranslations from '@/translations/CommonTranslations'
import CustomerModal from '@/pages/users/components/CustomerModal'
import constants from '@/constants'
import permissionsMixins from '@/mixins/permissionsMixins'
import formatMixins from '@/mixins/formatMixins'
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import settings from "@/settings";

export default {
  mixins: [ permissionsMixins, formatMixins, datatableMixins, httpMixins ],
  components: { CustomerModal, CustomerStatusHistoryButton },
	data() {
		return {
      taxes: [],
      fieldsExpand: 'creator,address,customerStatus,ordersSum',
      apiLink: 'user/get-customers',
      routePath: '/customers',
		}
	},
  props: {
    user: Object,
  },
  watch: {
    '$i18n.locale'() {
      this.unselectAllItems()
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    this.getTaxes()
	},
  methods: {
    changeCustomerStatus(newStatus, userId) {
      const foundUser = this.items?.find(i => i.id === userId)
      if (foundUser) {
        if (foundUser.customerStatus) {
          foundUser.customerStatus.status_id = newStatus
        } else {
          foundUser.customerStatus = {}
          foundUser.customerStatus.status_id = newStatus
        }
      }
    },
    async getItems(showSpinner = false) {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      if (showSpinner) {
        this.isLoading = true
      }
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
      this.unselectAllItems()
    },
    addNewItem() {
			// this.item = { role: constants.userRoles.customer }
			this.item = {}
			this.submitted = false
			this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
		},
    async deleteItem() {
      try {
        const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
        if (status === 204) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
          this.updatedItem = null

          this.searchData = ''
          this.$router.push({
            route: this.$route.path,
            query: {
              ...this.$route.query,
              search: '',
            }
          })

          this.getItems()
          this.closeConfirmItemDeleteModal()
        }
      } catch(err) {
        this.showError(err)
      }
    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	},
  computed: {
    allDataLoaded() {
      return !!(this.taxes)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return 3
      } else {
        return 2
      }
    },
    itemToDeleteData() {
      if (this.itemToDelete.type === constants.userTypes.legal_entity && this.itemToDelete.company_name) {
        return this.itemToDelete.company_name
      } else {
        return (this.itemToDelete.first_name ? (this.itemToDelete.first_name) : '')  + (this.itemToDelete.last_name ? ' ' + this.itemToDelete.last_name : '')
      }
    },
  }
}
</script>

<style scoped lang="scss">

// Original

//.item-image {
//	width: 100px;
//	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//}
//
//.p-dialog .item-image {
//	width: 150px;
//	margin: 0 auto 2rem auto;
//	display: block;
//}
//
//.confirmation-content {
//	display: flex;
//	align-items: center;
//	justify-content: center;
//}

.item-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
//
//@media screen and (max-width: 960px) {
//	::v-deep(.p-datatable) {
//		&.p-datatable-customers {
//			.p-datatable-thead > tr > th,
//			.p-datatable-tfoot > tr > td {
//				display: none !important;
//			}
//
//			.p-datatable-tbody > tr {
//				border-bottom: 1px solid var(--surface-d);
//
//				> td {
//					text-align: left;
//					display: block;
//					border: 0 none !important;
//					width: 100% !important;
//					float: left;
//					clear: left;
//					border: 0 none;
//
//					&:last-child{
//						text-align: center;
//					}
//
//					.p-column-title {
//						padding: .4rem;
//						min-width: 30%;
//						display: inline-block;
//						margin: -.4rem 1rem -.4rem -.4rem;
//						font-weight: bold;
//					}
//
//					.p-rating {
//						display: inline-block;
//					}
//				}
//			}
//		}
//	}
//
//	::v-deep(.p-toolbar) {
//		flex-wrap: wrap;
//
//		.p-button {
//			margin-bottom: .25rem;
//		}
//	}
//}
</style>
